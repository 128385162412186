@import "@/scss/_variables.scss";

























.radio-button-container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h6 {
    margin-bottom: 8px;
  }
}
